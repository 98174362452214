/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ED1B24;
	--ion-color-primary-rgb: 237,27,36;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #d11820;
	--ion-color-primary-tint: #ef323a;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}


@font-face {
  font-family: 'TBCT';
  font-style: normal;
  src: url('../assets/fonts/TradeGothic-BoldCondTwenty.ttf');
}

@font-face {
  font-family: 'TG';
  font-style: normal;
  src: url('../assets/fonts/TradeGothic.ttf');
}

@font-face {
  font-family: 'TGProBold';
  font-style: normal;
  src: url('../assets/fonts/TradeGothicLTPro-Bold.ttf');
}

@font-face {
  font-family: 'TGLight';
  font-style: normal;
  src: url('../assets/fonts/TradeGothic-Light.ttf');
}



.flex
{
    display: flex;
}

.flexd
{
    flex-direction: column;
}


.al-start
{
    align-items: flex-start;
}

.al-center
{
    align-items: center;
}

.al-end
{
    align-items: flex-end;
}

.jc-start
{
    justify-content: flex-start;
}


.jc-center
{
    justify-content: center;
}

.jc-end
{
    justify-content: flex-end;
}

.jc-between
{
    justify-content: space-between;
}

.jc-around
{
    justify-content: space-around;
}


/* @media (prefers-color-scheme: dark) {
 

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }


  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
} */

ion-content
{
  --background:#f7f7f7;
}

/* **********Login Page ****************************** */
.logo-holder
{
  margin-bottom: 10vh !important;
}

.logo-holder img
{
  height: 14vh;
}
.input-item
{
  --background: #EEF4FD;
  --border-radius: 7px;
  margin-bottom: 16px;
  
}


.input-item ion-label
{
  color: #6D6E72 !important;
  font-family: 'TGProBold' !important;
  font-size: 17px !important;
}

.input-item ion-input
{
  color: black !important;
  font-family: 'TG' !important;
  font-size: 18px !important;
  margin-top: 5px !important;

}


.submit-btn
{
   --background: #ED1B24 !important;
   --border-radius: 7px !important;
   --box-shadow: none !important;
   font-family: 'TGProBold' !important;
   height: 60px !important;
}

.forgot-holder
{
  margin-bottom: 20px;
}

.forgot-holder ion-router-link
{
  color: #6D6E72 !important;
  font-family: 'TGProBold' !important;
  text-decoration: underline !important;
}

.help-btn
{
  --color: #6D6E72 !important;
  font-family: 'TGProBold' !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.req-btn
{
  --background: #333436 !important;
   --border-radius: 7px !important;
   --box-shadow: none !important;
   font-family: 'TGProBold' !important;
   height: 60px !important;
   margin-top: 20px;
   margin-bottom: 30px;
}

.pp-holder
{
  bottom: 20px;
  position: absolute;
  left: 0;
  right: 0;
}

.privacy-policy p
{
  color: #585858 !important;
  font-family: 'TGLight' !important;
  font-size: 14px !important;
  margin: 5px 0 !important;
}

.privacy-policy p span
{
  font-family: 'TGProBold' !important;
  text-decoration: underline !important;
}

/* **************************************************** */



/* *************************forgot-password**************** */


.the-title h1
{
  color: #6D6E72;
  font-family: 'TGProBold';
  font-size: 26px;
  margin-top: 0;
}



.description h6
{
  color: #6D6E72;
  font-family: 'TG';
  font-size: 17px;
  margin: 20px 0;
}

.cancel-btn
{
  --color: #6D6E72 !important;
  font-family: 'TGProBold' !important;
  font-size: 16px !important;
  text-transform: none !important;
  margin-top: 20px;
  text-decoration: underline;
}

.main-title h3
{
  font-family: 'TGProBold' !important; 
  font-size: 26px;
}

.welcome-toolbar
{
  --background: #333436;
}

.welcome-toolbar ion-avatar
{
  margin: 10px 0 10px 16px;
}

.welcome-toolbar ion-title
{
  color: white;
  font-family: 'TGProBold' !important; 
}

.welcome-toolbar ion-buttons ion-button
{
  color: white;
}

.home .fake-bar
{
  background: #333436;
  height: 20vh;
  margin-top: -30px;
  position: fixed;
  width: 100%;
}

.home .first-card
{
  margin-top: 20px;
  background: #FFFFFF;
border: 1px solid #F3F3F3;
box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
border-radius: 7px;
}

.home .first-card ion-card-header
{
  color: #333436;
  font-family: 'TGProBold' !important; 
  font-size: 20px !important;
  padding: 0 !important;
  margin-bottom: 10px !important;
}

.home .first-card .card-content
{   
    color: #6D6E72 !important;
    font-family: 'TBCT' !important;
    font-size: 15px !important;
    margin-bottom: 20px;
    padding: 0;
}

.home .first-card .search-btn
{
  --background: #ED1B24 !important;
  font-family: 'TBCT' !important;
  height: 50px !important;
}

.home .rest-content .rest-main h4
{
  color: #333436;
  font-family: 'TGProBold' !important; 
  font-size: 22px !important;
  margin-bottom: 0 !important;
}

.home .rest-content .rest-sub p
{
  color: #6D6E72;
  font-family: 'TGProBold' !important; 
  font-size: 15px !important;
  margin: 10px 0 !important;
}

.metal-card
{
  background: #FFFFFF;
border: 1px solid #E7E7E7;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 7px;
margin: 0 0 20px 0 !important;
}
.metal-card ion-card-subtitle
{
  color: #6D6E72;
  font-family: 'TGProBold' !important; 
  font-size: 18px;
}

.metal-card ion-card-subtitle .avatar-name
{
  background: #FFE9EA;
  font-family: 'TGProBold' !important; 
  border-radius: 50%;
  color: #ED1B24;
  font-size: 16px;
  height: 2rem;
  width: 2rem;
  margin-right: 10px;
  
}

.metal-card .content h1
{
  color: #333436;
  font-family: 'TGProBold' !important; 
  font-size: 30px;
  margin-top: 0;
}

.metal-card .content h6
{
  color: #0AB22F;
  font-family: 'TGProBold' !important; 
  font-size: 15px;
}

ion-tab-bar
{
  /* background: transparent;
  position: absolute; */
  bottom: 0;
  height: 70px;
  z-index: 1000;
  width: 100%;
}

/*
ion-tab-button::after
{
  background: white;
  content: '';
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  z-index: -1;
}
*/

ion-tab-button {
  text-align: center !important;
  margin-top: -20px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
 
}

ion-tab-button ion-label {
  padding-top: 40px !important;
  z-index: 1000;
}

ion-tab-button:nth-child(1) {
  background: url('../../public/assets/imgs/tabicn/tab1.svg');
  background-size: 20px !important;
}



ion-tab-button:nth-child(2) {
  background: url('../../public/assets/imgs/tabicn/fabbig.png');
  background-size: 30px !important;
  margin-top: -30px !important;
 
}

ion-tab-button:nth-child(2) ion-label {
  padding-top: 48px !important;
}


ion-tab-button:nth-child(3) {
  background: url('../../public/assets/imgs/tabicn/tab2.svg');
  background-size: 20px !important;
  
}



ion-tab-button[aria-selected=true]:nth-child(1) {
  background: url('../../public/assets/imgs/tabicn/tab1a.svg');
}
ion-tab-button[aria-selected=true]:nth-child(2) {
  background: url('../../public/assets/imgs/tabicn/fabbiga.png');
  box-shadow: 0px -4px 7px rgba(0, 0, 0, 0.05);
}

ion-tab-button[aria-selected=true]:nth-child(3) {
  background: url('../../public/assets/imgs/tabicn/tab2a.svg');
}

.search-page-toolbar
{
  --background: #333436;
}

.search-page-toolbar ion-title
{
  --color: white;
  font-family: 'TGProBold' !important;
  font-size: 24px;
}

.search-page .description h4
{
    color: #333436;
     font-family: 'TGProBold' !important;
     font-size: 22px;
}

.search-page h6
{
    color: #6D6E72;
     font-family: 'TGProBold' !important;
     font-size: 15px;
     margin-top: 0;
}

.notification-toolbar, .photo-grading-toolbar, .settings-toolbar, .adjust-margin-toolbar,
.update-pass-toolbar, .need-help-toolbar, .new-grading-toolbar, .unit-detail-toolbar,
.result-toolbar, .rating-toolbar
{
  --background: #333436;
}

.notification-toolbar, .photo-grading-toolbar, .settings-toolbar, .adjust-margin-toolbar, 
.update-pass-toolbar, .need-help-toolbar, .new-grading-toolbar, .unit-detail-toolbar,
.result-toolbar, .rating-toolbar ion-title
{
  color: white;
  font-family: 'TGProBold' !important; 
}

.notifi-item
{
  position: relative;
  margin-bottom: 10px;
}

.notifi-avatar
{
  background: #FFE9EA;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  margin-right: 16px;
  position: relative;
}


.notifi-dot
{
  background: var(--ion-color-primary);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  
  bottom: 0;
  position: absolute;
  right: 5px;
}


.notifi-avatar ion-icon
{
  color: var(--ion-color-primary);
  font-size: 30px;
}

.notifi-item ion-label h2
{
  color: #333436;
  font-family: 'TGProBold' !important; 
  font-size: 20px;

}

.notifi-item ion-label h3
{
  color: #333436;
  font-family: 'TGProBold' !important; 
  font-size: 20px;

}

.notifi-item ion-label h6
{
  color: #6D6E72;
  font-family: 'TG' !important; 
  font-size: 12px;
  margin-top: 10px;

}

.notifi-item .end-slot p
{
  color: #000000;
  font-family: 'TG' !important; 
  font-size: 10px;
  margin: 0;

}

.segment-holder ion-segment-button
{
  text-transform: none !important;
  color: var(--ion-color-primary);
  font-family: 'TGProBold' !important; 
  font-size: 18px;
}

.empty-holder
{
  margin-top: 20vh;
}
.empty-holder img
{
  height: 10vh;
}

.empty-holder h5
{
  color: #6D6E72;
  font-family: 'TGProBold' !important;
  font-size: 20px;
}

.settings .profie-holder
{
  background: rgba(237, 27, 36, 0.05);
  padding: 16px;
  margin-bottom: 20px;
}

.settings .profie-holder ion-avatar
{
  height: 4rem;
  margin: 0 auto;
  width: 4rem;
}

.settings .profie-holder h2
{
  color: #333436;
  font-family: 'TGProBold' !important;
  font-size: 22px;
}

.settings .profie-holder .btnn-holder ion-button
{
  --border-radius: 7px;
  font-family: 'TBCT';
  font-size: 18px;
  height: 34px;
}

.settings ion-list ion-item
{
  --detail-icon-opacity:1;
  margin-bottom: 16px;
}
.settings ion-list ion-item .thumb
{
  background: #ECECEC;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
}

.settings ion-list ion-item ion-label
{
  color: #333436;
  font-family: 'TGProBold' !important;
  font-size: 18px;
}

.settings .bottom-holder
{
    bottom: 10px;
    left: 0;
    padding: 0 16px;
    position: absolute;
    right: 0;
    width: 100%;
}

.settings .bottom-holder ion-button
{
  font-family: 'TGProBold' !important;
  font-size: 18px;
  height: 50px;
}

.back-btn
{
  text-transform: none !important;
}


.adjust-margin .main-title h1
{
  color: var(--ion-color-primary);
  font-family: 'TGProBold' !important;
  font-size: 22px;
  margin-top: 0;
}

.adjust-margin .description
{
  color: #6D6E72;
  font-family: 'TG' !important;
  font-size: 16px !important;
  line-height: 22px;

}

.adjust-margin .marginn
{
  color: #333436;
  font-family: 'TGProBold' !important;
  font-size: 25px;
}

.adjust-margin .submit-btn
{
  --border-radius: 7px;
  font-family: 'TGProBold' !important;
  font-size: 18px;
  height: 60px;
}

.take-photo-btn
{
  font-family: 'TGProBold' !important;
  font-size: 16px;
  height: 3rem;
}

.unit-detail h3
{
    color: #000000;
    font-family: 'TGProBold' !important;
    font-size: 24px;
    margin-bottom: 0;
}

.unit-detail h4
{
    color: #7C7C7C;
    font-family: 'TGProBold' !important;
    font-size: 16px;
    margin: 5px 0;
}

.price-range-text
{
  margin-top: 10px;
}

.price-range-text h4
{
  color: var(--ion-color-primary);
  font-family: 'TGProBold' !important;
  font-size: 30px;
  margin-top: 0;
}

.range-holder
{
  margin-top: 5px;
}

.search-results .holder
{
  
  position: relative;
  height: 20vh;
}

.search-results .holder .back-holder
{
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: -1;
}

.search-results .holder .detail-holder
{
  background: rgba(0, 0, 0, 0.562);
  bottom: 0;
  height: fit-content;
  width: 100%;
  position: absolute;
  padding: 0 12px 5px 12px;
}


.search-results .holder .detail-holder h3
{
  bottom: 0;
  color: white;
  font-family: 'TGProBold' !important;
  font-size: 14px;
  margin: 5px 0;
}

.search-results .holder .detail-holder h5
{
  bottom: 0;
  color: #CBCBCB;
  font-family: 'TGProBold' !important;
  font-size: 11px;
  margin: 5px 0;
}

.search-results .holder ion-badge
{
  border-radius: 20px !important;
  --border-radius: 20px !important;
  padding: 5px 10px;
}
.routerr
{
  
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100 !important;
}

.slider-holdeee
{
  width: 100% !important;
}